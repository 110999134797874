<template>
  <div>
    <!-- Filters -->
    <products-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
      :office-filter.sync="officeFilter"
      :office-options.sync="officeOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="actions === 3 || actions === 2"
                variant="primary"
                :to="{ name: 'apps-product-add' }"
              >
                <span class="text-nowrap">Add Product</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchProducts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(images)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="120"
                :src="data.item.images"
                :to="{ name: '', params: { id: data.item.id } }"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(description)="data">
          <div class="" v-html="data.item.description"></div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status === true ? "Active" : "Inactive" }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(type_comission)="data">
          <p v-if="data.item.type_comission == 1">Percentage</p>
          <p v-else>Fixed</p>
        </template>

        <!-- Column: Status -->
        <template #cell(percentage_comission)="data">
          <p v-if="data.item.type_comission == 1">
            {{ data.item.percentage_comission }}%
          </p>
          <p v-else>${{ data.item.percentage_comission }}</p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="actions === 3 || actions === 2">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'apps-product-edit', params: { id: data.item.id }, query: { idOffice: officeFilter } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50" @click="updateStatus(data.item)">
                Update Status
              </span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
                @click="confirmDelete(data.item)"
              >
                Delete
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import ProductsListFilters from "./ProductsListFilters.vue";
import useProductsList from "./useProductList";
import ProductStoreModule from "../productStoreModule";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ProductsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },

  methods: {
    // confirm texrt
    confirmDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteProduct(data);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, ProductStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const toast = useToast();

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      officeFilter,
      officeData,
      planFilter,
      statusFilter,
      dataItemStatus,
      dataItemCountries,
    } = useProductsList();

    const roleOptions = dataItemCountries;

    const statusOptions = dataItemStatus;

    const productData = ref(null);
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;

    store
      .dispatch("app-user/fetchProducts")
      .then((response) => {
        productData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          productData.value = undefined;
        }
      });

    JSON.parse(localStorage.getItem("userData")).dataUser.rolId;

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      officeFilter,
      officeOptions: officeData,
      roleFilter,
      planFilter,
      statusFilter,
      actions: JSON.parse(localStorage.getItem("userData")).dataUser.rolId,

      updateStatus: (data) => {
        const { id, status, name } = data;
        const isTrueSet =
          status == String(status ? true : false)
            ? status
              ? true
              : false
            : !status
            ? true
            : false;
        store
          .dispatch("app-user/updateProductStatus", { status: isTrueSet, id })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The Status ${name} was update status correctly`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            refetchData();
          });
      },
      deleteProduct: (data) => {
        const { id, name } = data;
        data.idOffice = idOffice;
        data.idRol = idRol;

        store.dispatch("app-user/deleteProduct", { data }).then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `The Product was delete correctly`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          refetchData();
        });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
